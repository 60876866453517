@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Alert */
@layer components {
  .alert {
    @apply flex items-center p-4 mb-4 border-2 rounded-lg;
  }
  .success-alert {
    @apply bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative;
  }
  .error-alert {
    @apply bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative;
  }
  .close-button {
    @apply ml-auto -mx-1.5 -my-1.5 ml-2;
  }
  .success-button {
    @apply bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8;
  }
  .error-button {
    @apply ml-auto bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8;
  }
}
