@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  @apply font-sans;
}

@layer utilities {
  .text-custom-blue {
    color: #1a75ff;
  }

  .text-custom-green {
    color: #00cc66;
  }
}

@media print {
  .no-print {
    display: none;
  }

  .print-me {
    display: block;
  }
}


