@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  @apply font-sans;
}

.close {
  margin-left: auto
}

.popup-content {
  max-height: 80vh;
  overflow-y: auto;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.flex-center {
  @apply flex justify-between items-center
}

.tab-text-base {
  @apply text-xs lg:text-xl font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal
}

.tab-active {
  @apply text-white bg-teal-600
}

.tab-inactive {
  @apply text-teal-600 bg-white
}

.tab-ul {
  @apply flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row
}

.tab-li {
  @apply -mb-px mr-2 last:mr-0 flex-auto text-center
}

.form-section {
  @apply rounded-lg border border-black border-2 px-4 bg-main-green w-full text-white
}

.form-style {
  @apply border border-black text-base rounded-lg block w-full p-2.5
    bg-gray-100 text-black;
}

.form-style:disabled {
  @apply bg-gray-200 text-black cursor-not-allowed opacity-70;
}

.form-text-main {
  @apply bg-main-green text-white font-bold border-b-2 border-black rounded-t p-4 text-2xl
}

.form-secondary-area {
  @apply p-4 border-b border-black bg-white text-black hover:cursor-pointer hover:bg-gray-300
  transition ease-in-out duration-300;
}

.form-single-header {
  @apply bg-main-green text-white font-bold border-b-2 border-black rounded-t p-4 text-2xl
    hover:bg-gray-300 hover:text-main-green hover:cursor-pointer 
    transition ease-in-out duration-300;
}

.green-button {
  @apply text-white text-center font-medium rounded-lg text-xs sm:text-base font-bold py-1 px-4 border-2 border-black text-black mx-1
    hover:bg-teal-800 hover:cursor-pointer hover:border-main-green hover:text-white
    transition ease-in-out duration-300;
}

.page-container {
  @apply flex justify-center min-h-screen bg-white
}

.page-header {
  @apply px-2 pt-4 md:px-4 lg:px-8 w-full flex flex-col
}

.redButton {
  @apply text-white text-center font-medium rounded-lg text-sm
    hover:bg-red-800;
}

.remove-button {
  @apply text-black border-2 border-black text-center font-medium rounded-lg text-xl px-5 py-2.5 m-1
    hover:bg-red-800 hover:text-white hover:cursor-pointer
    transition ease-in-out duration-300;
}

.disabled-button {
  @apply text-slate-400 border-2 border-slate-400 text-center font-medium rounded-lg text-xl px-5 py-2.5 m-1;
}

.add-button {
  @apply text-black text-center font-medium rounded-lg text-sm lg:text-xl px-5 py-2.5 m-1
    hover:bg-teal-800 hover:text-white hover:cursor-pointer
    transition ease-in-out duration-200;
}

.add-button-small {
  @apply border-2 border-black text-black text-center font-medium rounded-lg text-xl px-5 py-2.5 m-1
  hover:bg-teal-800 hover:text-white hover:cursor-pointer
  transition ease-in-out duration-200;
}

.interview-button {
  @apply text-black text-center border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 m-1
    hover:bg-lighter-green hover:cursor-pointer
    transition ease-in-out duration-200;
}

.submit-button {
  @apply bg-gray-200 text-black text-center font-medium rounded-lg text-sm sm:text-lg md:text-xl border-2
    hover:bg-light-green hover:cursor-pointer
    transition ease-in-out duration-300;
}

.generate-button {
  @apply bg-white text-lighter-green text-center font-medium rounded-lg text-lg w-full px-5 py-3 m-1
    hover:bg-lighter-green hover:cursor-pointer hover:text-white
    border-4 border-black
    transition ease-in-out duration-300;
}

.save-button {
  @apply bg-white text-teal-800 text-center font-medium rounded-lg text-lg w-full px-5 py-3 m-1
    hover:bg-teal-800 hover:cursor-pointer hover:text-white
    border-4 border-black
    transition ease-in-out duration-300;
}

.remove-content-button {
  @apply inline-block align-middle text-red-800 text-center font-bold rounded-lg text-lg bg-white 
  p-2 border-2 border-red-800
  hover:bg-red-800 hover:text-white hover:cursor-pointer
  transition ease-in-out duration-300;
}

.left-right-spacing {
  @apply flex justify-between;
}

.form-container {
  @apply border border-black border-2 rounded-lg w-full my-4 bg-white
}

.form-label-text {
  @apply text-lg lg:text-2xl font-medium text-black
}

.login-button {
  @apply p-2 font-bold leading-none border border-4 rounded text-white border-white hover:border-transparent hover:bg-white hover:text-teal-800 items-center transition duration-300 ease-in-out
}

.action-button {
  @apply bg-main-green p-4 font-bold leading-none rounded text-white border hover:border-transparent hover:bg-gray-200 hover:text-teal-800 hover:border-black items-center transition duration-300 ease-in-out
}

.secondary-action-button {
  @apply bg-white p-4 font-bold leading-none rounded text-main-green border border-black hover:bg-main-green hover:text-white hover:border-main-green items-center transition duration-300 ease-in-out;
}

.dashboard-container {
  @apply m-4 pb-2 border border-4 rounded-lg border-black bg-white
}

.dashboard-header {
  @apply text-3xl font-bold bg-main-green p-2
}

.dashboard-button {
  @apply mx-4 mt-2 rounded-lg bg-darker-green hover:bg-light-green text-white font-bold py-2 px-4
    transition ease-in-out duration-300;
}

.dashboard-button-locked {
  @apply mx-4 mt-2 rounded-lg bg-gray-300 text-gray-800 font-bold py-2 px-4 
    cursor-not-allowed opacity-50 relative;
}

.pricing-popup {
  @apply fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-10 p-4
}

.dashboard-edit-button {
  @apply hover:cursor-pointer absolute bg-dark-green text-white rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100
    transition ease-in-out duration-300;
}

.dashboard-delete-button {
  @apply hover:cursor-pointer absolute bg-red-500 text-white rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100
    transition ease-in-out duration-300;
}

.phone-screen-stack {
  @apply flex flex-row items-center align-middle;
}

.cover-letter-container {
  @apply flex justify-center bg-white rounded-lg w-full my-4 bg-white
}

.left-section {
  @apply w-full md:w-1/2 mx-4
}

.right-section {
  @apply hidden md:flex sm:w-1/2 mx-4
}

.layover-container {
  @apply fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-10 p-4
}


/* clerk css */

.cl-card{
  border-radius: 4px !important;
}

.cl-formButtonPrimary{
  background-color: #115e59;
  color: white;
}

.cl-signIn-root{
  background-color: #115e59;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cl-signUp-root{
  background-color: #115e59;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden{
  display: none;
}

@keyframes slideUpAndFadeIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-slideUpFadeIn {
  animation: slideUpAndFadeIn 0.5s ease-out forwards;
}

.animate-slideUp {
  animation: slideUp 0.5s ease-out forwards;
}

.section-line {
  border-width: 1px;
  border-color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

@keyframes rollDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
